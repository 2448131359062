import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import trackEvent from '../components/analytics';
import Layout, {
  Player,
  ViewButtonPrevious,
  ViewButtonNext,
  ShareButtons,
  ViewModuleName,
  ViewChoice,
  ViewImgTop,
  Breadcrumbs,
} from '../components/layout';
import SEO from '../components/seo';
import Sound from '../audio/fr/voixoff_FR_17.mp3';
import Background from '../images/landscape-1.jpg';
import Borne3 from '../images/Bornes-3.png';

const ViewArticle = styled.article`
  display: flex;
  flex: 1 0 auto;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  max-width: 100%;
  text-align: center;
  background-image: url(${Background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  > * {
    animation: bounceIn;
    animation-duration: 1s;
  }
`;

class Part3_Success extends Component {
  render() {
    return (
      <Layout location={typeof window !== 'undefined' ? location : null}>
        <SEO pathname="/fr/part-3-success" />

        <ViewArticle>
          <h1>Bravo !</h1>
          <p>Tu as terminé ce module</p>
          <ShareButtons />
          <ViewModuleName>
            <ViewChoice>
              <ViewImgTop src={Borne3} />
              Mieux organiser mon activité
            </ViewChoice>
          </ViewModuleName>
        </ViewArticle>
        <Link to="/fr/part-3-quizz-form">
          <ViewButtonPrevious color="warning">
            <i className="fa fa-chevron-left fa" />
          </ViewButtonPrevious>
        </Link>
        <Link to="/fr/home">
          <ViewButtonNext
            color="warning"
            onClick={() => {
              trackEvent('Success Page Part 3', 'User finished the module', 'yes');
            }}
          >
            <i className="fa fa-chevron-right fa" />
          </ViewButtonNext>
        </Link>
        <Player sound={Sound} />
        <Breadcrumbs active={8} />
      </Layout>
    );
  }
}

export default Part3_Success;
